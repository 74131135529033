.selectplacement{
  flex-grow           : 1;
  max-width           : 1200px;
  top                 : 20px;
  position            : relative;
  padding             : 16px;
  padding-top         : 30px;
}

.mapposition{
  height              : 200px;
  width               : 100%;
  top                 : 0px;
  position            : relative;
}
.cardposition{
  top                 : 5px;
  position            : relative;
}
.addcardposition{
  top                 : 0px;
  position            : relative;
  max-width           : 1200px;
}
.tripcard{
  background          : #F1EDEC;
}
.shareposition{
  top                 :0px;
  position            :relative;
}
.valuespad{
  padding-bottom      :5px;
  display             :inline;
}
.carddisplay{
  display             :flex;
  flex-direction      :row;
}
.contentdisplay{
  flex-grow           :1;
}
.imgwidth{
  height              :128;
  width               :128;
  padding-top         :20px;
}
.tablespan{
  flex-grow           : 1;
  max-width           : 1200px;
  display             : table-row;
}
