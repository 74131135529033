.header1bar {
  background-color    : rgba(0,150,166,1.0);
  top                 : 0px;
  height              : 60px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}

.Toolbarcolor {
  background-color    : rgba(0,150,166,1.0);
  height              : 60px;
}

.headerlabel {
  flex-grow           : 1
}

.topbarcontentwrapper{
  max-width          : 1200px;
  display            : flex;
  position           : absolute;
  flex-flow          : row nowrap;
}

.header1div1{
  max-width          : 1200px;
}
