.searchcriteriagrid .searchcriteriatext {
  background-color    : rgba(255,255,255,0.0);
  top                 : 13px;
  height              : auto;
  width               : auto;
  position            : relative;
  margin              : 0;
  left                : 1px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 16.0px;
  color               : rgba(0, 0, 0, 0.38);
  text-align          : left;
}
.PresetDateRangePicker_button {
  position            : relative;
  height              : auto;
  width               : auto;
  text-align          : center;
  top                 : 13px;
  background          : 0 0;
  color               : rgba(0, 0, 0, 0.38);
  margin-right        : 0px;
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 16.0px;
  line-height         : normal;
  overflow            : visible;
  -moz-box-sizing     : border-box;
  box-sizing          : border-box;
  cursor              : pointer;
  text-align          : left;
}
.searchcriteriagrid .searchcriterialine {
  background-color    : rgba(0,0,0,0.12);
  top                 : 40px;
  height              : 2px;
  width               : auto;
  padding-left        : 10px;
  padding-right       : 20px;
  position            : relative;
  margin              : 0;
  left                : 1px;
  color               : rgba(0,0,0,0.12);
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);

}
.searchcriteriashortline {
  background-color    : rgba(0,0,0,0.12);
  top                 : 40px;
  height              : 2px;
  width               : 160px;
  position            : relative;
  margin              : 0;
  left                : 1px;
  color               : rgba(0,0,0,0.12);
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.searchcriteriagrid {
  display             : grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows  : repeat(2, 48px);
  top                 : 110px;
  position            : relative;
  grid-column-gap     : 50px;
  margin              : 50px;
  max-width           : 1200px;
  grid-row-gap        : 25px;
  width               : 100%;
}

.searchcriteriamaterialgrid{
  flex-grow           : 1;
  max-width           : 1200px;
  top                 : 50px;
  position            : relative;
  padding             : 10px;
}
.searchcriteriamaterialgridbef2{
  flex-grow           : 1;
  max-width           : 1200px;
  top                 : 20px;
  position            : relative;
  padding             : 10px;
  height              : 3600px;
  overflow            : 'auto';
}
.searchcriteriamaterialgrid2{
  flex-grow           : 1;
  max-width           : 1200px;
  top                 : 30px;
  position            : relative;
  padding             : 10px;
}
.searchcriteriamaterialgrid3{
  flex-grow           : 1;
  max-width           : 1200px;
  top                 : 40px;
  position            : relative;
  padding             : 10px;
}
.searchcriteriamaterialgrid4{
  flex-grow           : 1;
  max-width           : 1200px;
  top                 : 50px;
  position            : relative;
  padding             : 10px;
  padding-bottom      : 1px;
}
.searchcriteriamaterialgridnotop{
  flex-grow           : 1;
  max-width           : 1200px;
  position            : relative;
  padding             : 10px;
}

.createtrippadding{
  padding             : 30px;
  top                 : 20px;
  position            : relative;
  padding-top         : 30px;
}

.singlebuttoncard{
  top                 : 110px;
  position            : relative;

}
.singlebuttoncard .cardcontent{
  padding-bottom:              1px;
}


.searchcriteriagrid .line1 {
  grid-column: span 2;
}
.searchcriteriagrid .selectbox {
  grid-column         : 1/span 2;
  top                 : 10px;
  height              : auto;
  position            : relative;
  margin              : 0;
  left                : 1px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 16.0px;
  color               : rgba(0, 0, 0, 0.38);
  text-align          : left;
  z-index             : 80;
}
.CalendarDay__selected_span {
  background          : #82e0aa; /*background */
  color               : white; /*text */
  border              : 1px solid $light-red; /*default styles include a border*/
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background          : $dark-red;
  color               : white;
  z-index             : 100;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background          : orange;
  color               : white;
}

/* Will edit when the second date (end date) in a range of dates
 is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background          : brown;
}

.cardposition{
  top                 : 100px;
  position            : absolute;
}

.cardbar{
  background-color    : f5f5f5;
  width               : 100%;
  font-size           : 12px;
}
