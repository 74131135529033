.menubar .menutext1 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 15px;
  height              : auto;
  width               : 138px;
  position            : absolute;
  margin              : 0;
  left                : -0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 14.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : center;
  letter-spacing      : 0.5px;
  line-height         : 16.0px;
}
.menubar .menutext2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 15px;
  height              : auto;
  width               : 138px;
  position            : absolute;
  margin              : 0;
  left                : 138px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 14.0px;
  color               : rgba(255, 255, 255, 0.7);
  text-align          : center;
  letter-spacing      : 0.5px;
  line-height         : 16.0px;
}
.menubar .menutext3 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 15px;
  height              : auto;
  width               : 138px;
  position            : absolute;
  margin              : 0;
  left                : 276px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 14.0px;
  color               : rgba(255, 255, 255, 0.7);
  text-align          : center;
  letter-spacing      : 0.5px;
  line-height         : 16.0px;
}
.menubartop .menutexttop1 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 15px;
  height              : auto;
  position            : absolute;
  margin              : 0;
  left                : 30px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 14.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : center;
  letter-spacing      : 0.5px;
  line-height         : 16.0px;
}
.menutexttop2 {
  top                 : 6px;
  height              : auto;
  position            : relative;
  margin              : 0;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 14.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : right;
  letter-spacing      : 0.5px;
  line-height         : 16.0px;
  justify-content     : right;
  grid-column-start   : 3;
}
