.button{
  background-color    : rgba(0,187,211,1.0);
  color               : rgba(0,187,211,1.0);
  top                 : 50px;
  height              : 48px;
  width               : 120px;
  position            : relative;
  overflow            : hidden;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 14.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : center;
  letter-spacing      : 0.5px;
  line-height         : 16.0px;

}

.button_container{
  display             : flex;
  max-width           : 1200px;
  justify-content     : center;
  align-items         : center;
}

.button_light{
  background-color    : rgba(0,187,211,0.14);
  color               : rgba(0,187,211,0.14);
  top                 : 100px;
  height              : 48px;
  width               : 120px;
  position            : relative;
  margin              : 0;
  overflow            : hidden;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 14.0px;
  color               : rgba(50, 197, 255, 1.0);
  text-align          : center;
  letter-spacing      : 0.5px;
  line-height         : 16.0px;
  margin-left         : 45%;
}

.button_selected{
  background-color    : rgba(0,187,211,0.38);
  color               : rgba(0,187,211,0.38);
  top                 : 100px;
  height              : 48px;
  width               : 120px;
  position            : relative;
  margin              : 0;
  overflow            : hidden;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue", Helvetica, Arial, serif;
  font-size           : 14.0px;
  color               : rgba(50, 197, 255, 1.0);
  text-align          : center;
  letter-spacing      : 0.5px;
  line-height         : 16.0px;
  margin-left         : 45%;
}

.email_signin{
  color    : rgba(0,187,211,0.38);
}
