.menubar {
  background-color    : rgba(0,187,211,1.0);
  top                 : 60px;
  height              : 48px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  overflow            : hidden;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  max-width           : 1200px;
}

.menubartop {
  background-color    : rgba(0,187,211,1.0);
  top                 : 0px;
  height              : 60px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  overflow            : hidden;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}

.appbarcolor {
  background-color    : rgba(0,150,166,1.0);
  color               : rgba(0,150,166,1.0);
  background          : rgba(0,150,166,1.0);
  primary1color        : rgba(0,150,166,1.0);

}
