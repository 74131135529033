.header1bar .header1text {
  background-color    : rgba(255,255,255,0.0);
  top                 : 26px;
  height              : auto;
  width               : auto;
  position            : relative;
  margin              : 0;
  left                : 313px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "HelveticaNeue-Bold", Helvetica, Arial, serif;
  font-size           : 16.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : left;
  line-height         : 19.0px;
  grid-column-start   : 3;
}

.header1bar .header1bargrid {
  display             : grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows  : repeat(2, 48px);
  top                 : 26px;
  position            : absolute;
  margin              : 50px;
  max-width           : 1200px;
  width               : 100%;
}
